import React, { useState, useEffect } from 'react';
import LinkItem from './LinkItem';

function Header() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

  const handleLinkClick = (index) => {
    setActiveIndex(index);
    setIsNavbarCollapsed(true);
  };

  const toggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const links = [
    { title: 'Inicio', href: '#home' },
    { title: 'Acerca de', href: '#about' },
    { title: 'Servicios', href: '#service' },
    { title: 'Galeria', href: '#gallery' },
    { title: 'Citas', href: '#medical_appointment' },
    { title: 'Horarios', href: '#schedule' },
    { title: 'Iniciar sesión', href: '#', isButton: true, inConstruction: true },
  ];

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  return (
    <header className={`header_section ${isNavbarCollapsed ? '' : 'fullscreen'}`}>
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg custom_nav-container">
          <div className='row'>
            <div className="col-md-3">
              <a className="navbar-brand" href="index.html">
                <img src="images/logo-manan.png" alt="..." className="logo" />
              </a>              
            </div>
            <div className="col-md-9 d-flex align-items-center">
              <div
                className={`collapse navbar-collapse ${isNavbarCollapsed ? '' : 'show'}`}
                id="navbarSupportedContent"
              >
                <div className="d-flex mx-auto flex-column flex-lg-row align-items-center">
                  <ul className="navbar-nav">
                    {links.map((link, index) => (
                      <LinkItem
                        key={index}
                        title={link.title}
                        href={link.href}
                        isActive={index === activeIndex}
                        isButton={link.isButton}
                        inConstruction={link.inConstruction}
                        handleClick={() => handleLinkClick(index)}
                        isNavbarCollapsed={isNavbarCollapsed} 
                      />
                    ))}
                  </ul>
                </div>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                aria-controls="navbarSupportedContent"
                aria-expanded={!isNavbarCollapsed}
                aria-label="Toggle navigation"
                onClick={toggleNavbar}
              >
                <span className="navbar-toggler-icon" />
              </button>
            </div> 
          </div>         
        </nav>
      </div>
    </header>
  );
}

export default Header;