import React, { useState, useEffect, useCallback } from 'react';

const SliderGallery = () => {
  const images = [
    { src: 'images/img-gallery1.jpg', alt: 'Image 1' },
    { src: 'images/img-gallery2.jpg', alt: 'Image 2' },
    { src: 'images/img-gallery3.jpg', alt: 'Image 3' },
    { src: 'images/img-gallery4.jpg', alt: 'Image 4' },
    { src: 'images/img-gallery5.jpg', alt: 'Image 5' },
    { src: 'images/img-gallery6.jpg', alt: 'Image 6' },
    { src: 'images/img-gallery7.jpg', alt: 'Image 7' },
    { src: 'images/img-gallery8.jpg', alt: 'Image 8' },
    { src: 'images/img-gallery9.jpg', alt: 'Image 9' },
    { src: 'images/img-gallery10.jpg', alt: 'Image 10' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [thumbIndex, setThumbIndex] = useState(0);
  const thumbnailsToShow = 5; // Número de miniaturas que se mostrarán a la vez

  // Actualizar el índice del carrusel de miniaturas para desplazarse en grupos
  const updateThumbIndex = useCallback((index) => {
    if (index >= thumbIndex + thumbnailsToShow || index < thumbIndex) {
      const newIndex = Math.floor(index / thumbnailsToShow) * thumbnailsToShow;
      setThumbIndex(newIndex);
    }
  }, [thumbIndex, thumbnailsToShow]);

  const nextImage = useCallback(() => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % images.length;
      updateThumbIndex(nextIndex);
      return nextIndex;
    });
  }, [images.length, updateThumbIndex]);

  useEffect(() => {
    const interval = setInterval(nextImage, 6000);
    return () => clearInterval(interval);
  }, [nextImage]);

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
    updateThumbIndex(index);
  };

  // Función cíclica para avanzar miniaturas
  const nextThumbnails = () => {
    setThumbIndex((prevIndex) => {
      const newIndex = prevIndex + thumbnailsToShow;
      if (newIndex >= images.length) {
        // Si se supera el número total de imágenes, vuelve al inicio
        return 0;
      }
      return newIndex;
    });
  };

  // Función cíclica para retroceder miniaturas
  const prevThumbnails = () => {
    setThumbIndex((prevIndex) => {
      const newIndex = prevIndex - thumbnailsToShow;
      if (newIndex < 0) {
        // Si es menor que 0, muestra las últimas miniaturas
        return Math.max(0, images.length - thumbnailsToShow);
      }
      return newIndex;
    });
  };

  return (
    <div className='gallery-section' id="gallery">
      <h1 className="custom_heading3">                        
          <span>
              Nuestra
          </span>
          &nbsp;Galeria
      </h1>
      <div className="gallery-container">
        <div className="carousel">
          <img src={images[currentIndex].src} alt={images[currentIndex].alt} className="main-image" />
          <button
            className="carousel-button left"
            onClick={() => setCurrentIndex((currentIndex - 1 + images.length) % images.length)}
          >
            ❮
          </button>
          <button className="carousel-button right" onClick={nextImage}>
            ❯
          </button>
        </div>

        <div className="thumbnail-carousel">
          <button className="thumbnail-button left" onClick={prevThumbnails}>
            ❮
          </button>
          <div className="thumbnail-container">
            {images.slice(thumbIndex, thumbIndex + thumbnailsToShow).map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.alt}
                className={`thumbnail ${currentIndex === thumbIndex + index ? 'active' : ''}`}
                onClick={() => handleThumbnailClick(thumbIndex + index)}
              />
            ))}
          </div>
          <button className="thumbnail-button right" onClick={nextThumbnails}>
            ❯
          </button>
        </div>
      </div>
    </div>
  );
};

export default SliderGallery;