import React, { useState, useEffect } from 'react';
import useFetchData from '../hooks/useFetchData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const MedicalAppointmentSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    message: '',
    service: '',
    country: ''
  });

  const [showAlert, setShowAlert] = useState(false);

  const countries = [
    { name: 'Pais', code: ''},
    { name: 'Argentina', code: '+54', maxLength: 10 },
    { name: 'Bolivia', code: '+591', maxLength: 8 },
    { name: 'Chile', code: '+56', maxLength: 9 },
    { name: 'Colombia', code: '+57', maxLength: 10 },
    { name: 'Costa Rica', code: '+506', maxLength: 8 },
    { name: 'Cuba', code: '+53', maxLength: 8 },
    { name: 'Estados Unidos', code: '+1', maxLength: 10 },
    { name: 'Ecuador', code: '+593', maxLength: 9 },
    { name: 'El Salvador', code: '+503', maxLength: 8 },
    { name: 'España', code: '+34', maxLength: 9 },
    { name: 'Guatemala', code: '+502', maxLength: 8 },
    { name: 'Honduras', code: '+504', maxLength: 8 },
    { name: 'México', code: '+52', maxLength: 10 },
    { name: 'Nicaragua', code: '+505', maxLength: 8 },
    { name: 'Paraguay', code: '+595', maxLength: 9 },
    { name: 'Perú', code: '+51', maxLength: 9 },
    { name: 'Venezuela', code: '+58', maxLength: 10 },
    { name: 'República Dominicana', code: '+809', maxLength: 10 },
    { name: 'Uruguay', code: '+598', maxLength: 8 },
    { name: 'Venezuela', code: '+58', maxLength: 10 },

  ];

  const [submitData, setSubmitData] = useState(null);


  const { responseData, loading, error } = useFetchData(submitData);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getMaxDate = () => {
    const today = new Date();
    const nextMonth = new Date(today.setMonth(today.getMonth() + 3));
    return nextMonth.toISOString().split("T")[0];
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validar email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert('Por favor, introduce un email válido.');
      return;
    }

    if (formData.phone === "") {
      alert('Por favor, introduce un número de teléfono.');
      return;
    }

    if (formData.phone < 0) {
      alert('Por favor, introduce un número de teléfono válido.');
      return;
    }

    if (formData.name === "") {
      alert('Por favor, introduce tu nombre.');
      return;
    }

    if (formData.date === "") {
      alert('Por favor, selecciona una fecha.');
      return;
    }

    if (formData.country === "") {
      alert('Por favor, selecciona un pais.');
      return;
    }

    if (formData.service === "Servicio") {
      alert('Por favor, selecciona un servicio.');
      return;
    } 

    const selectedDate = new Date(formData.date);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); 
    if (selectedDate <= currentDate) {
      alert('Por favor, selecciona una fecha futura.');
      return;
    }

    const emailData = {
      from: 'fundacionmananpasto@gmail.com',
      to: formData.email,
      subject: `Cita para ${formData.name}`,
      body: formData.message,
      service: formData.service,
      country: formData.country,
      phone: formData.phone,
      name: formData.name,
      date: formData.date
    };
    
    setSubmitData(emailData);
  };

  useEffect(() => {
    if (responseData) {
      setShowAlert(true);
      setFormData({
        name: '',
        email: '',
        phone: '',
        date: '',
        message: '',
        service: '',
        country: ''
      });
    }

    const alertTimeout = setTimeout(() => {
      setShowAlert(false);
    }, 6000);

    return () => clearTimeout(alertTimeout);
  }, [responseData]);

  return (
    <section className="medical_appointment" id="medical_appointment">
      <div className="form-container">
        <div className="row">
          <div className='col-md-6 image'>
            <img src="images/simbolo.png" alt="..." style={{ paddingRight: '22%' }} />
          </div>
          <div className="col-md-6" style={{ padding: '7% 1%' }}>
            <form className='form-cita' onSubmit={handleSubmit}>
              <div className="text-center">
              <h2 className="custom_heading">    
                Agende su          
                <span>
                  &nbsp;Cita
                </span>
                  
              </h2>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <input
                    type="text"
                    name="name"
                    placeholder="Nombre Completo"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-md-6'>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <input
                    type="number"
                    name="phone"
                    placeholder="Teléfono"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-md-6'>
                  <input
                    type="date"
                    name="date"
                    placeholder="Fecha"
                    value={formData.date}
                    min={getCurrentDate()}
                    max={getMaxDate()}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <select className="custom-select"
                    name="service"
                    value={formData.service || ''}
                    onChange={handleChange}>
                    <option selected="">Servicio</option>
                    <option value="AP">Atención psicologica</option>
                    <option value="EN">Enfermeria</option>
                    <option value="TF">Terapia fisica</option>
                    <option value="CP">Capacitacion</option>
                    <option value="CH">Charla</option>                    
                  </select>
                </div>
                <div className='col-md-6'>
                  <select
                    className="custom-select"
                    name="country"
                    value={formData.country || ''}
                    onChange={handleChange}
                  >
                    {countries.map(country => (<option value={country.code}>{country.name}</option>))}
                  </select>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="message-box"
                  name="message"
                  placeholder="Mensaje"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit">
                  ENVIAR
                </button>
              </div>
              {/* Mostrar estado de carga o errores */}
              {showAlert && (
                <div className="alert alert-info mt-3" role="alert">
                  Se envio un correo con los datos de la solicitud a su correo.
                  <br />
                  <strong>Nota:</strong> Si el correo no aparece en su bandeja de entrada, porfavor verifique la carpeta de correos no deseados o spam.
                </div>
              )}
              {loading && (
                <div className="loading-icon">
                  <FontAwesomeIcon icon={faSpinner} spin /> Enviando...
                </div>
              )}
              {error && (
                <div className="error-icon">
                  <FontAwesomeIcon icon={faExclamationCircle} /> Error en el envío
                </div>
              )}
            </form>            
          </div>
        </div>
      </div>
    </section>
  );
};

export default MedicalAppointmentSection;