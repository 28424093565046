import ReadMore from './ReadMore';
function Slider() {
  return (
    <>
        <section className="slider_section position-relative" id="home">
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">            
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 offset-md-2">
                        <div className="slider_detail-box">
                            <h1 className="slide-right">                        
                                <span>
                                    Fundación Manan 
                                </span>
                                &nbsp;M&M
                            </h1>
                            <p>
                                <strong>
                                    <ReadMore maxChars={210} isSlider={true}>
                                        {'Fundación Manan M&M trabajamos para brindar servicios integrales de atención domiciliaria que aborden las necesidades físicas, emocionales y sociales de nuestros usuarios, generando bienestar a los pacientes y sus familias'}
                                    </ReadMore>
                                    
                                </strong>
                            </p>                        
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="slider_img-box">
                            <img src="images/img-slider6.png" alt="..." />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 offset-md-2">
                        <div className="slider_detail-box">
                            <h1 className="slide-right">                        
                                <span>
                                    Nuestros 
                                </span>
                                &nbsp;servicios
                            </h1>
                            <p>
                                <strong>
                                    <h5>Atención medica en la comodidad del hogar</h5>
                                    <ul>
                                        <li>Atención psicologica</li>
                                        <li>Enfermeria</li>
                                        <li>Terapia fisica</li>
                                        <li>Trabajo social</li>
                                        <li>Capacitaciones y charlas</li>                                        
                                    </ul>
                                </strong>
                            </p>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="slider_img-box other">
                            <img src="images/psicologia1.png" alt="..." />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 offset-md-2">
                        <div className="slider_detail-box">
                            <h1 className="slide-right">                             
                                <span>
                                    Nuestra
                                </span>
                                    &nbsp;Misión
                            </h1>
                            <p>
                                <strong>
                                    <ReadMore maxChars={225} isSlider={true}>
                                        {'En Fundación Manan M & M nos dedicamos a promover y mejorar la calidad de vida de personas en situación de vulnerabilidad, enfermedad o discapacidad. A través de la prestación de servicios integrales de atención domiciliaria en las áreas de enfermería, psicología, trabajo social y terapia física. Buscamos ofrecer un acompañamiento profesional, humano y personalizado que contribuya al bienestar físico, mental y emocional de nuestros beneficiarios, fomentando su autonomía, dignidad y pleno desarrollo en su entorno familiar y comunitario.'}
                                    </ReadMore>
                                </strong>
                            </p>                        
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="slider_img-box other">
                            <img src="images/img-slider7.png" alt="..." />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 offset-md-2">
                        <div className="slider_detail-box">
                            <h1 className="slide-right">                             
                                <span>
                                    Nuestra
                                </span>
                                    &nbsp;Visión
                            </h1>
                            <p>
                                <strong>
                                    <ReadMore maxChars={225} isSlider={true}>
                                        {'Buscamos ser una organización líder en atención domiciliaria integral, reconocida por nuestra dedicación y excelencia en el servicio a personas en situación de vulnerabilidad, enfermedad o discapacidad. Aspiramos a crear un modelo de cuidado que inspire a otras entidades y profesionales del sector, promoviendo un enfoque humanizado y holístico en la atención de quienes más lo necesitan.'}
                                    </ReadMore>
                                </strong>
                            </p>                       
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="slider_img-box other">
                            <img src="images/tool.png" alt="..." />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
        </section>
    </>
  );
}

export default Slider;