import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPhone, faMobile } from '@fortawesome/free-solid-svg-icons';
import SliderGallery from './SliderGallery';
import ReadMore from './ReadMore';
import MedicalAppointment from './MedicalAppointment';

function Home() {
  return (
    <>
<div>
  {/* about section */} 
  <section className="about_section layout_padding" id="about">
    <div className="container">
      <div className="row"> 
        <div className="col-md-6">
          <div className="img-box">
            <img src="images/doctora1.png" alt="..."/>
          </div>
        </div>
        <div className="col-md-6">
          <div className="detail-box">
            <h2 className="custom_heading">    
              Acerca de          
              <span>
                &nbsp;Nosotros
              </span>
                
            </h2>
            <p>
              Bienvenidos a Fundación Manan M & M, una organización dedicada a proporcionar atención 
              domiciliaria integral en las áreas de psicología, enfermería, terapia física y trabajo social. 
              Nuestro compromiso es mejorar la calidad de vida de nuestros pacientes y sus familias a través 
              de una atención humanizada y personalizada.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* service section */}
  <section className="service_section layout_padding" id="service"> 
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 offset-md-2">
          <h2 className="custom_heading2">
            Nuestros <span>Servicios</span>
          </h2>
          <div className="container layout_padding2">
            <div className="row">
              <div className="col-md-4">
                <div className="img_box">
                  <img src="images/psicologia.png" alt="..."/>
                </div>
                <div className="detail_box">
                  <h6>
                    Psicología
                  </h6>                  
                  <div>                      
                      <ReadMore>
                          {'Ofrecemos evaluaciones y tratamientos psicológicos para ayudar a los pacientes a gestionar el estrés, la ansiedad, la depresión y otros desafíos emocionales. Capacitaciones a empresas e instituciones educativas'}
                      </ReadMore>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="img_box">
                  <img src="images/enfermeria-logo.png" alt="..."/>
                </div>
                <div className="detail_box">
                  <h6>
                    Enfermería
                  </h6>
                  <div>                      
                      <ReadMore>
                          {'Proporcionamos atención de enfermería especializada, que incluye la administración de medicamentos, cuidados postoperatorios, manejo de enfermedades crónicas y más'}
                      </ReadMore>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="img_box">
                  <img src="images/fisioterapia.png" alt="..."/>
                </div>
                <div className="detail_box">
                  <h6>
                    Terapia Física
                  </h6>
                  <div> 
                    <ReadMore>
                        {'Desarrollamos programas de rehabilitación física personalizados para mejorar la movilidad, reducir el dolor y aumentar la independencia funcional de los pacientes'}
                    </ReadMore>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container layout_padding2">
            <div className="row justify-content-center">              
              <div className="col-md-4">
                <div className="img_box">
                  <img src="images/trabajo-social.png" alt="..."/>
                </div>
                <div className="detail_box">
                  <h6>
                    Trabajo Social
                  </h6>
                  <div>                      
                      <ReadMore>
                          {'Brindamos apoyo y asesoramiento en cuestiones sociales y familiares, incluyendo la gestión de recursos comunitarios y la planificación de cuidados a largo plazo'}
                      </ReadMore>
                  </div>
                  <p>
                  </p>
                </div>
              </div>       
            </div>
          </div>
        </div>
        <div className="col-md-4 servicios">
          <img src="images/tool.png" alt="..."className="w-100" />
        </div>
      </div>
    </div>
  </section>

  {/* gallery section */}
  <SliderGallery/>

  {/* Medical apointment section */}
  <MedicalAppointment/>

  {/* Date section */}
  <section className="buy_section" id="schedule">
    <div className="container_contact">
      < div className="container2">
        <div className="info">
          <h2>Equipo de Profesionales Comprometidos</h2>          
          <div>
            <ReadMore maxChars={225}>
                {'Contamos con un equipo de profesionales altamente capacitados y dedicados, incluyendo psicólogos, enfermeros, terapeutas físicos y trabajadores sociales, todos comprometidos con la misión de nuestra fundación. Cada miembro de nuestro equipo aporta su experiencia y conocimientos para ofrecer un cuidado integral y personalizado a cada paciente siempre con el objetivo de mejorar su calidad de vida y bienestar'}
            </ReadMore>            
          </div>          
        </div>
        <div className="contact">
          <h2>Horarios de atención</h2>
          <div className="contact-item">
            <p><FontAwesomeIcon icon={faClock} /> <strong>Lunes - Viernes: </strong>&nbsp;8AM-12PM</p>
          </div>
          <div className="contact-item">
            <p><FontAwesomeIcon icon={faClock} /> <strong>Lunes - Viernes: </strong>&nbsp;2PM-6PM</p>
          </div>
          <div className="contact-item">
            <p><FontAwesomeIcon icon={faClock} /> <strong>Sábado:</strong>&nbsp;8AM-12PM</p>
          </div>
          <div className="contact-item">
            <p><FontAwesomeIcon icon={faPhone} /> <strong>Telefono:</strong>&nbsp;(602) 734-1335</p>
          </div>
          <div className="contact-item">
            <p><FontAwesomeIcon icon={faMobile} /> <strong>Celular:</strong>&nbsp;(311) 8429388</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  {/* info section */}
  <section className="info_section layout_padding2">
    <div className="container">
      <div className="info_items">
        <a href>
          <div className="item ">
            <div className="img-box box-1">              
            </div>
            <div className="detail-box">
              <p>
                Cra 19 #29-13 B/El calvario Pasto - Nariño
              </p>
            </div>
          </div>
        </a>
        <a href>
          <div className="item ">
            <div className="img-box box-2">
              
            </div>
            <div className="detail-box">
              <p>
                (602) 734-1335
              </p>
            </div>
          </div>
        </a>
        <a href>
          <div className="item ">
            <div className="img-box box-3">
              
            </div>
            <div className="detail-box">
              <p>
                fundacionmananpasto@gmail.com
              </p>
            </div>
          </div>
        </a>
      </div>
      <div className="social-media">
        <a href="https://www.facebook.com/share/wrA5cQxrDjQY74ug/?mibextid=qi2Omg/">
          <img src="images/facebook.png" alt="Facebook"/>
        </a>
        <a href="https://www.instagram.com/fundacion_manan?igsh=OXkydDIzYmtuM3Ny/">
          <img src="images/instagram.png" alt="Instagram"/>
        </a>
        <a href="https://www.tiktok.com/@fun.manan.mym?_t=8oeKZwHJzTK&_r=1/">
          <img src="images/tiktok.png" alt="tiktok"/>
        </a>
        <a href="https://youtube.com/@fundacionmananmym?si=ABw4VwnGqSnYDBRS/">
          <img src="images/youtube.png" alt="youtube" className='youtube'/>
        </a>
      </div>
    </div>
    <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
  </section>
  {/* end info_section */}
</div>

    </>
  );
}

export default Home;
