import React, { useState } from 'react';
import classNames from 'classnames';

const ReadMore = ({ children, maxChars = 136, isSlider= false }) => {
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    const buttonClass = classNames('btn', 'btn-link', {
        'slider-header': isSlider === true,
    });

    return (
        isSlider ? (
            <div>
            
                <p>
                    {isReadMore ? children.slice(0, maxChars) : children}
                    <span>{isReadMore ? '...' : ''}
                        <button onClick={toggleReadMore} className={buttonClass}>
                            {isReadMore ? 'Leer más' : 'Leer menos'}
                        </button>
                    </span>
                </p>           
            </div>
        ) : (<div>
            
            <p>
                {isReadMore ? children.slice(0, maxChars) : children}
                <span>{isReadMore ? '...' : ''}</span>
                <button onClick={toggleReadMore} className={buttonClass}>
                    {isReadMore ? ' Leer más' : ' Leer menos'}
                </button>
            </p>
            
            
        
    </div>)
    );
};

export default ReadMore;