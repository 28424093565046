import React, { useState } from 'react';

const LinkItem = ({ title, href, isActive, isButton, handleClick, inConstruction, isNavbarCollapsed }) => {
  const [showNotification, setShowNotification] = useState(false);

  const handleButtonClick = (e) => {
    if (inConstruction) {
      e.preventDefault(); // Previene la acción por defecto del enlace (incluyendo cerrar el navbar)
      setShowNotification(true); // Muestra la notificación
      setTimeout(() => setShowNotification(false), 3000); // Oculta la notificación después de 3 segundos
    } else {
      if (handleClick) handleClick(); // Llama a la función de click si no está en construcción
    }
  };

  return (
    <li className={`nav-item ${isButton ? 'position-relative' : ''}`}>
      <div className='link-item'>
        <a
          href={href}
          className={`${isActive ? 'nav-link active-item' : 'nav-link'} ${isButton ? (isNavbarCollapsed ? 'btn-link' : 'nav-link') : ''}`}
          onClick={handleButtonClick}
        >
          {title}
        </a>
      </div>
      {inConstruction && showNotification && (
        <div className="notification">¡Esta sección está en construcción!</div>
      )}
    </li>
  );
};

export default LinkItem;